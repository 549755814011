import React from "react"

import Intro from "components/Intro/Intro"
import Layout from "components/Layout/Layout"

import ContactForm from "components/ContactUs/ContactForm"

import SEO from "components/SEO/SEO"

const Home = () => (
  <Layout showNavigation showSocialCol showFooter>
    <SEO />
    <Intro />
    <div id="contact">
      <ContactForm />
    </div>
  </Layout>
)

export default Home
