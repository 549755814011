import React from "react"
import Typed from "react-typed"

import Form from "components/Form/Form"

import "./ContactForm.scss"

const ContactForm = () => {
  return (
    <section className="Contact">
      <div id="contact-content" className="ConstactSection">
        <h2 className="ConstactSection__greeting">
          Get in touch{" "}
          <span className="emoji" role="img" aria-label="Emoji telephone">
            &#9742;
          </span>
        </h2>
        <h3 className="ConstactSection__subheader">
          <Typed
            loop
            typeSpeed={60}
            backSpeed={20}
            strings={[
              "Having a new project in mind or need help with an existing project? ",
              "Looking for an expert: designer?",
              "Looking for an expert: developer?",
              "Looking for an expert: thought leader?",
              "No matter at what point you are with your project, we'll be happy to help.",
            ]}
            smartBackspace
            backDelay={1000}
            loopCount={1}
            showCursor
            cursorChar="|"
            className="typed"
          />
        </h3>
        <Form />
      </div>
    </section>
  )
}

export default ContactForm
