import React from "react"
import { navigate } from "gatsby-link"
import { useForm } from "react-hook-form"

import Link from "components/_ui/Link/Link"

import "./Form.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Form = () => {
  const {
    register,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const onSubmit = state => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...state,
      }),
    })
      .then(() => navigate("/thanks/"))
      .catch(error => alert(error))
  }

  return (
    <form
      name="contact"
      method="POST"
      action="/thanks/"
      data-netlify-honeypot="bot-field"
      data-netlify="true"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="wrapper">
        <input type="hidden" name="bot-field" value="contact" />

        <div className="Column">
          <input
            type="text"
            className="Row"
            name="username"
            placeholder="Full Name"
            required
            ref={register({ required: true })}
          />

          <input
            type="text"
            className="Row"
            name="companyname"
            placeholder="Company name"
            required
            ref={register({ required: true })}
          />
        </div>
        <div className="Column">
          <input
            type="text"
            className="Row"
            name="role"
            placeholder="Your role"
            required
            ref={register({ required: true })}
          />

          <input
            type="email"
            className="Row"
            name="email"
            placeholder="Email"
            required
            ref={register({ required: true, pattern: /^\S+@\S+$/i })}
          />
        </div>

        <textarea
          type="text"
          className="Row"
          name="usermessage"
          placeholder="Your message"
          required
          ref={register({ required: true })}
        />

        <Link
          doOpenInNewTab
          isButton
          type="submit"
          className="Form__button"
          buttonProps={{ size: "l", color: "white-blue-stroke" }}
        >
          Submit
        </Link>
      </div>
    </form>
  )
}
export default Form
