import React from "react"
import Typed from "react-typed"
import Link from "components/_ui/Link/Link"
import MaxWidth from "components/_ui/MaxWidth/MaxWidth"
import Particles from "components/Particles/Particles"

import "./Intro.scss"

const Intro = () => {
  return (
    <MaxWidth size="m" className="Intro">
      <Particles />
      <h2 className="Intro__greeting">Hello! </h2>
      <h1 className="Intro__header">
        <Typed
          loop
          typeSpeed={60}
          backSpeed={20}
          strings={[
            "We're <span class='accent'>Promidon Inc</span>.",
            "We use <span class='accent'> technology to...</span>",
            "enable",
            "empower",
            "and evolve <span class='accent'>humanity</span>.",
            "We <span class='accent'>create</span> stuff...",
          ]}
          smartBackspace
          backDelay={1000}
          loopCount={1}
          showCursor
          cursorChar="|"
          className="typed"
        />
      </h1>
      <h3 className="Intro__subheader">
        We provide enterprise solutions to help drive social impact that pushes
        Canada to be a global leader in the Tech space. We do this by developing
        inclusive solutions to help transform the lives of those our technology
        serve &#129312;.
      </h3>
      <Link
        doOpenInNewTab
        isButton
        className="Intro__button"
        buttonProps={{ size: "xl", color: "white-blue-stroke" }}
        to={"/#contact"}
      >
        Get in touch
      </Link>
    </MaxWidth>
  )
}

export default Intro
